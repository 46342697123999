import Vue from "vue";
import http from "@/plugins/http/http";

export let store = Vue.observable(
  {
    dicts: null,
    isSilence: false,
    count: 0,
  });

export let mutations = {
  getDicts() {
    return new Promise((resolve, reject) => {
      if (store.dicts) {
        resolve(store.dicts);
      } else {
        http.get("dicts/mapTree").then(data => {
          store.dicts = data;
          resolve(store.dicts);
        }).catch(e => {
          reject(e);
        });
      }
    });
  },
  setSilence() {
    store.isSilence = !store.isSilence;
  },
  setCount(count) {
    store.count = count;
  },
};
