<template>
  <el-input
    :clearable="clearable"
    :maxlength="0"
    :value="value"
    class="vm-select-input"
    :placeholder="placeholder"
    :disabled="disabled"
    @change="change"
    @clear="clear">
    <div v-if="!disabled" slot="append" class="iconWrapper" @click="onSelect">
      <i class="el-icon-search"></i>
    </div>
  </el-input>
</template>

<script>
  export default {
    props: {
      value: [String, Number],
      clearable: Boolean,
      disabled: Boolean,
      placeholder: {
        type: String,
        default: "请选择",
      },
    },
    methods: {
      onSelect() {
        this.$emit("select");
      },
      change(val) {
        this.$emit("input", val);
      },
      clear() {
        this.$emit("clear");
      },
    },
  };
</script>

<style lang="scss">
.vm-select-input {
  .iconWrapper {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: -20px -20px;
    height: 40px
  }
}

</style>
