export default {
  genKey() {
    return this.randomString(8);
  },
  randomString(e) {
    e = e || 32;
    let t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
    let a = t.length;
    let n = "";
    for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
    return n;
  },
  /**
   * 字符串脱敏
   * @param str 字符串
   * @param frontLen 字符串前面保留位数
   * @param endLen 字符串后面保留位数
   */
  desensitize(str, frontLen = 2, endLen = 4) {
    let len = str.length - frontLen - endLen;
    let xing = "";
    for (let i = 0; i < len; i++) {
      xing += "*";
    }
    return str.substring(0, frontLen) + xing + str.substring(str.length - endLen);
  },
  //上移
  up(arr, index) {
    if (index === 0) {
      return;
    }
    arr[index] = arr.splice(index - 1, 1, arr[index])[0];
  },
  //下移
  down(arr, index) {
    if (index === arr.length - 1) {
      return;
    }
    arr[index] = arr.splice(index + 1, 1, arr[index])[0];
  },
};
