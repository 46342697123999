import axios from "axios";
import auth from "../../util/auth";
import {router, toLogin} from "@/router";
import {Notification} from "element-ui";
import i18n, {myI18n} from "@/lang";

// axios 配置
axios.defaults.timeout = 60000;
// axios.defaults.baseURL = 'http://localhost:8080/';
axios.defaults.baseURL = window.config.SERVER_URL;

// http request 拦截器
axios.interceptors.request.use(
  config => {
    config.headers["authorization"] = auth.getToken();
    config.headers["current-org"] = auth.getCurrentOrgId();
    config.headers["Pragma"] = "no-cache"; // 防止ie下get方法缓存
    config.headers["Accept-Language"] = i18n.locale; // 国际化
    config.headers["zoneId"] = getZoneId(); // 时区
    return config;
  },
  err => {
    return Promise.reject(err);
  });

// http response 拦截器
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.log("-------------接口请求错误信息start-------------");
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status === 401) {
        toLogin(router.currentRoute.path);
      }
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      Notification.error({title: myI18n("common.hint","提示"), message: error.response.data.message});
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
      Notification.error({title: myI18n("common.hint","提示"), message: myI18n("common.failedServer","连接服务器失败")});
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
      Notification.error({title: myI18n("common.hint","提示"), message: myI18n("common.requestError","请求服务器出错")});
    }
    console.log("-------------接口请求错误信息end-------------");
    return Promise.reject(error);
  });

function getZoneId() {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    console.error(e);
    return "Asia/Shanghai";
  }
}

export default axios;
