import axios from "./axios";

/**
 * 通用-Get方法获取数据
 */
export function getData(url, params) {
  return axios({
    url: url,
    method: "get",
    params: params,
  });
}

/**
 * 通用-Get方法获取数据,设置了超时时间（秒）
 */
export function getDataT(url, params, timeout) {
  return axios({
    timeout: timeout * 1000,
    url: url,
    method: "get",
    params: params,
  });
}
// 导出
export function exportFile(url, params, timeout) {
  return axios({
    timeout: timeout * 1000,
    url: url,
    method: "get",
    params: params,
    responseType: "blob",
  });
}

/**
 * 通用-获取列表
 */
export function getList(moduleName, params) {
  return getData(moduleName, params);
}

/**
 * 通用-根据id获取某个模块数据
 */
export function getById(moduleName, id) {
  return axios({
    url: moduleName + "/" + id,
    method: "get",
  });
}

/**
 * 通用-新增或者修改
 */
export function save(moduleName, model) {
  return axios({
    url: moduleName,
    method: (!model.id || model.id === 0) && (!model.pkid || model.pkid === 0) ? "post" : "put",
    data: model,
  });
}

/**
 * 通用-根据id删除
 */
export function deleteById(moduleName, id) {
  return axios({
    url: moduleName + "/" + id,
    method: "delete",
  });
}
