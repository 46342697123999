<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

  export default {
    name: "App",
    mounted() {
      document.title = this.$tenant.name;
      let link = document.querySelector("link[rel~='icon']") || {};
      link.href = this.$tenant.favicon;
    },
  };
</script>
<style>
#app {
  height: 100vh;
}
</style>
